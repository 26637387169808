body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
#root {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ugh, this is because the mui data table filter is not wide enough.
 * ... this stylez all popoverz :/
 */
@media (min-width: 480px) {
  .MuiPopover-paper {
    min-width: 380px !important;
  }
}
@media (max-width: 480px) {
  .MuiPopover-paper {
    min-width: 90vw !important;
  }
}

/* forgive me for all these "hackky" "mobile" stylez */
@media (max-width: 600px) {
  #root {
    overflow-x: hidden;
  }

  .MuiToolbar-root > div:first-child {
    max-width: 100px;
  }
  .MuiToolbar-root > div:nth-child(3) {
    flex: 1;
  }
  .MuiToolbar-root > div:nth-child(2) {
    display: none;
  }

  .MuiToolbar-gutters {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .MTableToolbar-actions-311 .MuiButtonBase-root {
    padding: 6px;
  }

  .MuiGrid-root,
  .MuiTable-root {
    padding: 0px !important;
  }
  .MuiTableCell-root {
    padding: 6px !important;
  }
  .MuiTableRow-root td:nth-child(-n + 3):not(.MuiTableCell-footer) {
    max-width: 100px;
    word-break: break-all;
  }

  .MyOrders .MuiTableRow-root td:nth-child(-n + 3):not(.MuiTableCell-footer) {
    max-width: unset;
  }

  .MuiTableRow-root {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: thin solid;
    height: auto !important;
  }

  .MuiTableRow-root:last-child {
    border-bottom: none;
  }
}
