/* search stuff should prolly move to its own place :/ */
.search_container {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: sticky;
  top: 0;
}
.search {
  font-size: 1.25rem;
  border: 1px solid lavender;
  border-width: 4px 6px 1.5rem 6px;
  border-radius: 6px;
  padding: 9px;
  width: 300px;
  background: white;
  color: black;
}

.search_container input {
  margin-bottom: -22px;
  margin-left: -4px;
  border-radius: 9px;
  /* box-shadow: inset 0px 0px 6px 2px rgba(176, 196, 222, 0.8); */
}

.search_container label {
  display: inline-block;
  width: 300px;
  margin-top: 1rem;
  padding: 0 0.25rem 0.25rem 0.25rem;
  border-radius: 6px;
  /* background: white; */
  color: black;
}

.search:focus {
  outline: none;
}

.filters_btn {
  height: 30px;
  width: 30px;
  border: 1px solid plum;
  border-width: 1px 2px 6px 2px;
  border-radius: 4px;
  margin-left: 0.5rem;
  background-color: white;
  font-size: 1rem;
  margin-left: -40px;
  position: absolute;
  top: 26px;
  cursor: pointer;
}
.filters_btn:hover {
  border-color: black;
}

.filters {
  width: 300px;
  position: absolute;
  top: 90px;
  border: 1px solid plum;
  border-radius: 6px;
  background-color: plum;
  padding: 3px;
  text-align: center;
}

.search_container label.cat_label {
  border-radius: 0px;
  border: none;
  margin: 4px 0 0 0;
  background-color: plum;
  text-align: left;
  width: 99%;
  text-transform: uppercase;
}

.filters > select {
  width: 98%;
  border: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.filters .filter_reset {
  margin: 6px 0 0 0;
  text-align: right;
}

.filters .filter_reset button,
.filters .shopping_list .add_to_list {
  background-color: white;
  border-color: black;
  border-width: 2px 4px 8px 4px;
  border-radius: 6px;
}

.filters .shopping_list {
  text-align: left;
  padding: 0 0.5rem;
  text-align: right;
  max-height: 40vh;
  overflow-y: scroll;
  /* border-top: 1px solid black; */
}
.filters .shopping_list .shopping_list_title {
  position: sticky;
  top: 0;
  background-color: plum;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filters .shopping_list input {
  border-radius: 3px;
  margin: 0.25rem;
  border: thin solid black;
  border-width: 2px 4px 8px 4px;
  border-radius: 6px;
  font-size: 1rem;
}

.filters .shopping_list_items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filters .shopping_list_items input.item {
  flex-grow: 1;
  max-width: 187px;
}

.filters .shopping_list_items .done {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: line-through;
  text-align: left;
  width: 200px;
}
.filters .shopping_list_items button.item {
  flex-grow: 1;
  background-color: white;
  border-color: black;
  border-width: 2px 4px 8px 4px;
  border-radius: 6px;
}

/* grid stuff */
.grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 98vw;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  max-width: 300px;
  word-break: break-word;
}

.card:hover,
.card:focus,
.card:active {
  cursor: default;
  border-color: var(--box-shadow-color-hover);
  /* --box-shadow-color: black; */
  box-shadow: 0px 9px 0px 3px var(--box-shadow-color-hover);
}

.card > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card h2 {
  padding-right: 0.5rem;
  font-size: 2rem;
  display: inline-block;
  margin-block: 0.6rem;
  word-break: keep-all;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.1;
}

.card .codes {
  margin-top: 3px;
  font-size: 0.7rem;
  word-break: keep-all;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3px;
  grid-auto-rows: minmax(30px, auto);
}
.card .code {
  padding: 1px 1px 3px 1px;
  border: thin solid black;
  border-width: 1px 2px 4px 2px;
  border-radius: 4px;
  text-align: center;
}

.card > .cardSelected {
  display: flex;
  position: absolute;
  margin-top: -1.5rem;
  margin-left: -1.5rem;
  border-radius: 9px;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.card > .cardSelected > div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.75rem;
  font-weight: 800;
}

.card > .cardSelected > div:hover {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  user-select: none;
}

.card > .cardSelected > div,
.card > .cardSelected > input {
  height: 3.5rem;
  /* width: 33%; */
  width: 100%;
  border: thin solid black;
  border-width: 2px 4px 8px 4px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 2rem;
}

.card > .cardSelected > input {
  padding-left: 6px;
  min-width: 84px;
}

.cat {
  position: sticky;
  top: 1rem;
  left: 1rem;
  background: inherit;
  padding: 1.25rem 1.25rem 0 1.25rem;
  width: 330px;
  border: solid 3px gray;
  border-radius: 9px;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  word-break: break-word;
}

.cat h2 {
  padding-bottom: 1rem;
}

@media (max-width: 1024px) {
  .search_container {
    align-items: flex-end;
    margin-right: 1rem;
  }
}

@media (max-width: 600px) {
  .search_container {
    align-items: center;
  }

  .search_container label {
    margin-top: 0.5rem;
  }
  .filters_btn {
    top: 17px;
    padding: 0px 7px;
  }
  .filters {
    top: 82px;
  }

  .cat {
    position: sticky;
    top: 84px;
    width: 100vw;
    margin: 1rem;
    padding: 0.25rem;
  }
  .cat h1,
  .cat h2 {
    margin-block: 0;
    font-size: 1rem;
    padding-bottom: 0;
  }
  .card {
    width: 100vw;
    padding: 0.5rem;
    margin: 0.5rem;
  }
  .card h2 {
    font-size: 1.25rem;
  }
  .card p {
    font-size: 1rem;
  }

  .card > .cardSelected {
    margin-top: -8px;
    margin-left: -8px;
  }
}
